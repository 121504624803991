<template>
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <b-form
        @submit.stop.prevent="handleSubmit(createItem)"
    >
      <b-card>
        <b-row>
          <b-col md="6" xl="6" class="mb-1 mt-2">
            <validation-provider
                #default="validationContext"
                :name="$t('Credits')"
                rules="required|integer"
            >
              <b-form-group :label="$t('# Credits')">
                <b-form-input
                    v-model="currentItem.credits"
                    id="credits"
                    :placeholder="$t('# Credits')"
                    type="number"
                />

                <small class="text-danger">{{ validationContext.errors[0] }}</small>
                <small v-if="validate && Object.keys(validation).includes('credits')" class="text-danger"
                >{{ $t(validation.credits[0]) }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-button
            variant="success"
            type="submit"
        >
          {{ $t('Add credits') }}
        </b-button>
      </b-card>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormFile,
  BAvatar,
  BMedia,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted, mounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { required, alphaNum, email, intege } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import moment from 'moment'
import Cleave from 'vue-cleave-component'



export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    vSelect,
    BFormFile,
    BAvatar,
    BMedia,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      firstname: '',
      currentItem: {
        credit: 0
      },

      loaded: true,
      validation: {},
      validate: false,
    }
  },

  methods: {
    createItem() {
      let url = `/clients/${this.$route.params.clientid}/users/${this.$route.params.userid}/addcredits`
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false

            if (success) {
              this.$http.post(url, this.currentItem)
                  .then(response => {
                    this.$router.push(
                        {
                          name: 'apps-clients-users-list',
                          params: {
                            clientid: this.$route.params.clientid,
                          }
                        },
                        () => setTimeout(() => {
                          this.$bvToast.toast(this.$i18n.t('Credits successfully added'), {
                            title: this.$i18n.t('Success'),
                            variant: 'success',
                            toaster: 'b-toaster-bottom-right'
                          })
                        })
                    )
                  })
                  .catch(error => {
                    if (error.response.status == 422) {
                      this.validation = error.response.data.errors
                      this.validate = true
                    } else {
                      this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t('Error'),
                        variant: 'danger',
                        toaster: 'b-toaster-bottom-right'
                      })
                    }
                  })
            }
          })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },
  },
  beforeMount() {
    this.addBreadcrumbDataFromRouteParams(this.$route.params)
  },
  mounted() {
    this.helperGetItem(
        `/clients/${this.$route.params.clientid}/users/${this.$route.params.userid}`,
        function(data, context) {
          let breadcrumbsData = [
            {
              key: 'Edit User',
              value: data.firstname + " " + data.name
            },
            {
              key: 'Clients Edit',
              value: data.client
            }
          ]

          context.replaceBreadcrumbsPlaceholders(breadcrumbsData)
        }
    )
  },
}
</script>

